var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"name":"applyName","label":"姓名","placeholder":"请输入姓名","required":"","readonly":"","disabled":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
        label: '证件类型',
        required: true,
        readonly: true,
        disabled: true,
        placeholder: '请选择证件类型',
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":"","readonly":"","disabled":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field-select',{attrs:{"options":_vm.homeTypeOption,"fieldProps":{
        label: '房间类型',
        required: true,
        placeholder: '请选择房间类型',
      }},model:{value:(_vm.form.homeType),callback:function ($$v) {_vm.$set(_vm.form, "homeType", $$v)},expression:"form.homeType"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }